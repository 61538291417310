export const timeFormats = {
  minutes: {
    title: 'Minutes',
    unit: 'm',
    periods: [1, 5, 10, 15, 30, 45],
  },
  hours: {
    title: 'Hours',
    unit: 'h',
    periods: [1, 2, 3, 6, 8, 12],
  },
  days: {
    title: 'Days',
    unit: 'd',
    periods: [1, 2, 3, 5],
  },
  weeks: {
    title: 'Weeks',
    unit: 'w',
    periods: [1, 2, 3, 4],
  },
};

export const relativeTimeFormats = {
  oneHours: {
    title: 'H',
    periods: 1,
    unit: 'h',
  },
  threeHours: {
    title: 'H',
    periods: 3,
    unit: 'h',
  },
  twelveHours: {
    title: 'H',
    periods: 12,
    unit: 'h',
  },
  days: {
    title: 'D',
    periods: 1,
    unit: 'd',
  },
  weeks: {
    title: 'W',
    periods: 1,
    unit: 'w',
  },
};
