import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'store';
import QueryVariable from './DashboardSettings/Variables/QueryVariable';
import ConstantVariable from './DashboardSettings/Variables/ConstantVariable';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import Grid from '@mui/material/Grid';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import variableStore from '../utils/variableStore';

const VariablesContainer = ({ children, direction }) => {
  return (
    <>
      {direction === 'column' && (
        <Grid container alignItems="center">
          {children}
        </Grid>
      )}
      {direction === 'row' && (
        <Stack direction="row" spacing={1} alignItems="center">
          {children}
        </Stack>
      )}
    </>
  );
};

const VariablesBar = ({
  variables,
  onVariableChange,
  defaultRegion,
  compact,
  direction = 'row',
  timeRange,
  exports = false,
}) => {
  const {
    workspace: { workspace },
  } = useSelector((s) => s);
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();

  // Check URL params on initial load and when queryParams change
  useEffect(() => {
    if (!variables || !queryParams) return;

    variables.forEach((variable) => {
      const queryValue = queryParams[`var-${variable.name}`];

      const parsedValue = variable.is_multi
        ? queryValue
          ? queryValue.split(',')
          : []
        : queryValue;

      const valuesAreEqual = Array.isArray(variable.value)
        ? Array.isArray(parsedValue) &&
          JSON.stringify(parsedValue) === JSON.stringify(variable.value)
        : parsedValue === variable.value;

      if (queryValue !== undefined && !valuesAreEqual) {
        onVariableChange(parsedValue, variable);
      } else if (!queryValue && variable.value) {
        updateUrlParams(variable.name, variable.value);
      }
    });
  }, [queryParams, variables]);

  // Update URL params when variables change
  const updateUrlParams = (name, value) => {
    if (exports) return;
    if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)
    ) {
      queryParams[`var-${name}`] = '';
    } else {
      queryParams[`var-${name}`] = Array.isArray(value)
        ? value.join(',')
        : value;
    }

    const newSearch = qs.stringify(queryParams);
    const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    variableStore.setItem(location.pathname.split('/')[3], newSearch);
    history.replace(newUrl);
  };

  const onViewModeVariableChange = (value, variable) => {
    updateUrlParams(variable.name, value);
    onVariableChange(value, variable);
  };

  return (
    <Stack
      direction={direction}
      alignItems={'center'}
      flexWrap={'wrap'}
      rowGap={direction === 'row' && 1}
      my={1}
      spacing={1}
    >
      {(variables || []).map((variable) => {
        const queryValue = queryParams[variable.name];
        const effectiveValue =
          queryValue !== undefined
            ? variable.is_multi
              ? queryValue.split(',')
              : queryValue
            : variable.value;

        const effectiveVariable = {
          ...variable,
          value: effectiveValue,
        };

        return (
          <VariablesContainer key={variable.id} direction={direction}>
            <Grid item xs={3}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Typography>{variable.label || variable.name}</Typography>
                {variable.hint && (
                  <Tooltip title={variable.hint} placement="top" arrow>
                    <SvgIcon fontSize="1rem">
                      <InfoOutlinedIcon />
                    </SvgIcon>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid item xs={5}>
              {variable.type === 'query' && (
                <QueryVariable
                  isViewMode
                  workspaceId={workspace.id}
                  variable={effectiveVariable}
                  viewModeOnChange={(value) =>
                    onViewModeVariableChange(value, variable)
                  }
                  resolvedVariablesValues={variables}
                  defaultRegion={defaultRegion}
                  timeRange={timeRange}
                />
              )}
              {variable.type === 'constant' && (
                <ConstantVariable
                  isViewMode
                  variable={effectiveVariable}
                  viewModeOnChange={(value) =>
                    onViewModeVariableChange(value, variable)
                  }
                />
              )}
            </Grid>
          </VariablesContainer>
        );
      })}
    </Stack>
  );
};

export default VariablesBar;
