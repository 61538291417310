import moment from 'moment-timezone';

const countryCodesToName = moment.tz.countries().reduce((acc, code) => {
  acc[code] = new Intl.DisplayNames(['en'], { type: 'region' }).of(code) || code;
  return acc;
}, {});

const localTimezone = moment.tz.guess();

const timezonesMap = new Map();

Object.keys(countryCodesToName).forEach((code) => {
  const timezones = moment.tz.zonesForCountry(code) || [];
  timezones.forEach((tz) => {
    if (!timezonesMap.has(tz)) {
      const offset = `UTC${moment.tz(tz).format('Z')}`;
      timezonesMap.set(tz, {
        countryCode: code,
        label: countryCodesToName[code],
        timezone: tz,
        offset: offset,
      });
    }
  });
});

const sortedTimezones = Array.from(timezonesMap.values()).sort((a, b) => {
  return a.timezone.localeCompare(b.timezone);
});

const ALL_TIMEZONES = [
  {
    timezone: localTimezone,
    label: 'Browser Time',
    offset: `UTC${moment.tz(localTimezone).format('Z')}`,
  },
  { timezone: 'UTC', label: 'Coordinated Universal Time', offset: 'UTC+00:00' },
  ...sortedTimezones,
];

export default ALL_TIMEZONES;
