import moment from 'moment';
import keyBy from 'lodash/keyBy';
import { DEFAULT_REGION } from 'constants/aws-regions';
import { DATA_SOURCES, DATA_SET } from 'constants/data-sources';
import { defaultAgg, getAlarmsList, getIssuesList } from 'modules/alarms/utils';
import { parseValue } from 'components/DateRangePickerNew';
import {
  OTEL_KEY,
  OTEL_VALUES,
} from '../components/DashboardSettings/Variables/QueryVariable';
import isNil from 'lodash/isNil';
import { otelClient } from 'api';
import { fetchLogs, fetchTraces } from 'api/otel';

export const calculateTimeRange = (val, timeshift, inSeconds = true) => {
  const { from, to } = parseValue(val, timeshift);
  if (inSeconds) {
    return {
      from: moment().utc().diff(from, 'seconds'),
      to: moment().utc().diff(to, 'seconds'),
    };
  }
  return { from, to };
};

const buildPayload = (values, workspaceId) => {
  const isTablePanel = values.panelType === 'table';
  return {
    params: values.node_configs.map((ele) => {
      return {
        ...ele,
        time_range: calculateTimeRange(
          values.timeRange,
          values.time_options?.override ? values.time_options?.time_shift : '',
        ),
        legendFormat: !isTablePanel && ele.legendFormat,
      };
    }),
    workspace_id: workspaceId,
  };
};

const transformTimeSeries = (nodes, input) => {
  const nodeConfigs = keyBy(input.node_configs, 'nodeId');

  const labelsHash = {};
  const modifiedNodes = nodes.map((node, index) => {
    if (node.legend) {
      return { ...node, name: node.legend };
    }
    if (node.type !== 'series') {
      return node;
    }
    const { nodeId, labels } = node;
    const config = nodeConfigs[node.nodeId];
    let name;
    if (node.name === nodeId) {
      name = `${nodeId} ${labels ? Object.values(labels).join(' ') : ''} ${
        config.metric || ''
      }`;
    } else if (Object.keys(labels).length) {
      name = `${node.name} ${config.metric || ''}`;
    } else {
      name = node.name;
    }
    if (labelsHash[name]) {
      labelsHash[name] = labelsHash[name].concat(index);
    } else {
      labelsHash[name] = [index];
    }
    return node.type === 'series'
      ? {
          ...node,
          name,
        }
      : node;
  });

  const duplicateLabels = Object.values(labelsHash).reduce(
    (prev, curr) => (curr.length > 1 ? prev.concat(curr) : prev),
    [],
  );

  return modifiedNodes.map((node, index) => {
    return duplicateLabels.includes(index)
      ? { ...node, name: `${node.name} ${nodeConfigs[node.nodeId].stat || ''}` }
      : node;
  });
};

const resolveConfig = (config, variableValues, defaultRegion) => {
  let resolvedConfig = JSON.stringify(config);
  const isPromQL =
    config.query_type === 'promql' &&
    config.datasourceId === DATA_SOURCES.KLOUDMATE;

  const getVariableReplacements = (variable) => {
    const replacements = [];
    if (isNil(variable.value)) {
      return replacements;
    }

    const variableType = variable.config?.queryType;
    let replaceWith;

    // multi value variables
    if (variable.is_multi) {
      if (variable.value.some((v) => v === 'none')) {
        const regex = '\\$' + variable.name;
        replaceWith = '';
        replacements.push({
          regex,
          replaceWith,
        });
        return replacements;
      }

      if (isPromQL) {
        const regex = '\\$' + variable.name;
        if (variable.value.some((v) => v === '*')) {
          replaceWith = '.*';
        } else if (variableType === OTEL_VALUES) {
          // in promql join multiple values using |
          replaceWith = variable.value.map((v) => v.field || v).join('|');
        } else if (variableType === OTEL_KEY) {
          // in promql join multiple keys using ,
          replaceWith = variable.value.map((v) => v.field || v).join(', ');
        }

        replaceWith &&
          replacements.push({
            regex,
            replaceWith,
          });

        return replacements;
      }

      const regex = '"\\$' + variable.name + '"';
      if (variableType === OTEL_VALUES) {
        replaceWith = `"${variable.value.join(',')}"`;
      } else if (variableType === OTEL_KEY) {
        replaceWith = JSON.stringify(
          variable.value.map((v) =>
            typeof v === 'string' ? { field: v, type: 'string' } : v,
          ),
        )
          .slice(1)
          .slice(0, -1); // remove first and last array brackets
      } else if (variableType === 'dimension_values') {
        replaceWith = JSON.stringify(variable.value).slice(1).slice(0, -1);
      } else {
        replaceWith = `"${variable.value}`;
      }

      replacements.push({
        regex,
        replaceWith,
      });

      return replacements;
    }

    // single value variables

    if (variable.value === 'none') {
      const regex = '\\$' + variable.name;
      replaceWith = '';
      replacements.push({
        regex,
        replaceWith,
      });
      return replacements;
    }

    if (isPromQL) {
      const regex = '\\$' + variable.name;
      if (variable.value === '*') {
        replaceWith = '.*';
      } else if (variableType === OTEL_VALUES) {
        replaceWith = variable.value;
      } else if (variableType === OTEL_KEY) {
        replaceWith = variable.value.field;
      }
      replacements.push({
        regex,
        replaceWith,
      });

      return replacements;
    }

    const regex = '"\\$' + variable.name + '"';
    if (variableType === OTEL_VALUES) {
      replaceWith = `"${variable.value}"`;
    } else if (variableType === OTEL_KEY) {
      [
        { find: 'field', replace: 'field' },
        { find: 'type', replace: 'type' },
        { find: 'key', replace: 'field' },
      ].forEach(({ find, replace }) => {
        const regex = `(?<=\"${find}\":)` + '"\\$' + variable.name + '"';
        replacements.push({
          regex,
          replaceWith: `"${variable.value[replace]}"`,
        });
      });

      replaceWith = JSON.stringify(
        typeof variable.value === 'string'
          ? { field: variable.value, type: 'string' }
          : variable.value,
      );
    } else {
      replaceWith = `"${variable.value}"`;
    }

    replacements.push({
      regex,
      replaceWith,
    });
    return replacements;
  };

  (variableValues || [])
    .concat({ name: 'default', value: defaultRegion || DEFAULT_REGION })
    .forEach((variable) => {
      const replacements = getVariableReplacements(variable);

      replacements.forEach(({ regex, replaceWith }) => {
        const regExp = new RegExp(regex, 'gi');
        resolvedConfig = resolvedConfig.replace(regExp, replaceWith);
      });
    });

  try {
    return JSON.parse(resolvedConfig);
  } catch (error) {
    return config;
  }
};

export const resolveTemplateVariables = (
  values,
  variableValues,
  defaultRegion,
) => {
  if (Array.isArray(values.node_configs)) {
    return {
      ...values,
      node_configs: values.node_configs.map((config) =>
        resolveConfig(config, variableValues, defaultRegion),
      ),
    };
  }
  return resolveConfig(values, variableValues, defaultRegion);
};

export const runQuery = async (values, workspaceId) => {
  const res = await otelClient.post(
    'queries/run',
    buildPayload(values, workspaceId),
  );

  if (!res) {
    return [];
  }

  return res;
};

export const runAlarmQuery = async (values, workspaceId) => {
  if (!values?.node_configs) {
    return null;
  }

  const isNonAgg = values.node_configs.some(
    (node) => node.query_type === 'raw',
  );
  if (isNonAgg) {
    const { dataset, limit, filters, columns, orderBy, page, nodeId } =
      values.node_configs[0];

    const { from, to } = calculateTimeRange(
      values.timeRange,
      values.time_options?.time_shift,
      false,
    );

    const payload = {
      workspaceId,
      from,
      to,
      limit,
      page,
      filters,
      columns,
      orderBy,
    };

    let rows, total;
    if (dataset === DATA_SET.TRACES) {
      const { spans, count } = await fetchTraces(payload, true);
      rows = spans;
      total = count;
    }
    if (dataset === DATA_SET.LOGS) {
      const { logs, count } = await fetchLogs(payload);
      rows = logs;
      total = count;
    }
    if (dataset === DATA_SET.ALARMS) {
      const result = await getAlarmsList(payload);
      rows = result.alarms;
      total = result.total;
    }
    if (dataset === DATA_SET.ISSUES) {
      const result = await getIssuesList(payload);
      rows = result.rows;
      total = result.aggregate.aggregate.count;
    }

    const result = [
      {
        nodeId,
        rows,
        total,
        page,
        limit,
        type: 'raw',
      },
    ];
    return result;
  }

  const res = await runQuery(values, workspaceId);

  const nodes = Object.keys(res)
    .map((nodeId) => res[nodeId])
    .flatMap((node) => node.frames);

  return transformTimeSeries(nodes, values);
};

export const FOLDERS = 'folder';
export const DASHBOARDS = 'dashboard';

export const getVariableUsage = (variable, panels, variables) => {
  const regExp = (varName) => new RegExp('\\$' + varName, 'gi');
  const variableConfig = JSON.stringify(variable.config);

  return {
    usedInPanels: panels
      .filter(
        (panel) => JSON.stringify(panel).match(regExp(variable.name))?.length,
      )
      .map((panel) => ({ id: panel.id, name: panel.title })),
    usedInVariables: variables
      .filter((v) => v.id !== variable.id)
      .filter((v) => JSON.stringify(v).match(regExp(variable.name))?.length)
      .map((v) => ({ id: v.id, name: v.name })),
    usesVariables: variables
      .filter((v) => variableConfig.match(regExp(v.name))?.length)
      .map((v) => ({ id: v.id, name: v.name })),
  };
};

export const DEFAULT_PANEL_CONFIG = {
  nodeId: 'A',
  type: 'query',
  stat: '',
  region: '',
  source: DATA_SOURCES.KLOUDMATE,
  namespace: '',
  dimensions: [],
  aggregation: defaultAgg,
  filters: [],
  interval: '',
  groupBy: [],
  orderBy: [],
  limit: undefined,
  page: 1,
  metricType: '',
  metric: '',
  dataset: DATA_SET.METRICS,
  time_range: {
    from: 600,
    to: 0,
  },
  hidden: false,
};

export const DEFAULT_PANEL_GRID_CONFIG = {
  w: 4,
  h: 2,
  x: 0,
  y: 0,
  minW: 2,
  minH: 1,
};

export const parseType = (value) => {
  if (typeof value !== 'string') {
    return 'string';
  }
  const val = value.toLowerCase();
  if (val === 'true' || val === 'false') return 'bool';

  const num = parseFloat(val);
  if (!isNaN(num) && isFinite(value)) return 'number';

  return 'string';
};

// Report layout constants
export const REPORT_LAYOUTS = {
  grid: {
    w: 4, 
    h: 2,
    minW: 4,
    minH: 2,
  },

  simple: {
    w: 12,
    h: 2,
    minW: 12,
    minH: 2,
  },
};

// Get the layout for the report
// query: layout=grid or layout=simple
export const getReportLayout = (panels, layoutType) => {
  if (!layoutType) return null;

  const config = REPORT_LAYOUTS[layoutType];
  if (!config) return null;

  return panels.map((panel, index) => {
    if (layoutType === 'grid') {
      // For grid layout: 3 panels per row
      const row = Math.floor(index / 3);
      const col = (index % 3) * config.w;
      return {
        ...config,
        i: panel.id,
        x: col,
        y: row * config.h
      };
    } else if (layoutType === 'simple') {
      // For simple layout: 1 panel per row
      return {
        ...config,
        i: panel.id,
        x: 0,
        y: 0
      };
    }
  });
};