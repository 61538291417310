import { makeStyles } from '@mui/styles';

export const relativeStyles = makeStyles((theme) => ({
  wrapper: {},
  list: {
    maxHeight: '200px',
    overflow: 'auto',
  },
}));

export const pickerStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: '300px',
    maxWidth: '720px',
    display: 'flex',
    height: '320px',
  },
  left: {
    minWidth: '300px',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.lighter,
    display: 'flex',
    flexDirection: 'column',
  },
  right: {
    'width': '520px',
    'flex': 1,
    'padding': '12px',
    'background': theme.palette.background.paper,
    'display': 'flex',
    'flexDirection': 'column',
    '& form': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    overflow: 'auto',
  },
}));
