import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TimeZones from 'utils/timezones';
import { Box } from '@mui/material';
import TextInput from '../Form/TextInput';

const Timezones = ({ value, onChange }) => {
  const [search, setSearch] = useState('');

  const filteredTimezones = TimeZones.filter((tz) => {
    const searchTerm = search.toLowerCase();
    return (
      tz.timezone.toLowerCase().includes(searchTerm) ||
      tz.label.toLowerCase().includes(searchTerm) ||
      tz.offset.toLowerCase().includes(searchTerm)
    );
  });

  return (
    <>
      <Box px={1}>
        <TextInput
          fullWidth
          size="small"
          margin="none"
          placeholder="Search timezone..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <List sx={{ maxHeight: '300px', overflow: 'auto' }}>
        {filteredTimezones.map((z) => (
          <ListItemButton
            key={z.timezone}
            selected={z.timezone === value}
            onClick={() => onChange(z.timezone)}
          >
            <ListItemText primary={`${z.timezone} (${z.label}) ${z.offset}`} />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export default Timezones;
