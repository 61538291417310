// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        color: theme.palette.text.primary,
        underline: 'always',
      },
      styleOverrides: {
        root: {
          textDecorationStyle: 'dotted',
          fontWeight: 500,
        },
      },
    },
  };
}
