import React from 'react';
import Box from '@mui/material/Box';
import Select from 'components/Form/Select';
import TimeseriesTable from './TimeseriesTable';
import DataTable from 'components/DataTable';

export const getLabelsString = (labels) => {
  const keys = Object.keys(labels || {}).sort();
  return keys
    .reduce((acc, curr) => {
      const val = labels[curr];
      acc.push(`${curr}=${val}`);
      return acc;
    }, [])
    .join(', ');
};

export const ValueTable = ({ frames, valueFormatter = (value) => value }) => {
  const columns = [
    {
      text: 'Label',
      dataField: 'label',
      formatter: (label, row) => label || row.name,
    },
    {
      text: 'value',
      dataField: 'value',
      formatter: valueFormatter,
    },
  ];
  const tableData = frames.map((frame) => ({
    label: getLabelsString(frame.labels),
    value: frame.values[0],
    name: frame.name,
  }));

  return (
    <DataTable
      tableContainerStyle={{ maxHeight: 100 }}
      columns={columns}
      data={tableData}
      pagination={false}
    />
  );
};

const ExpressionResult = ({ data }) => {
  const [selected, setSelected] = React.useState(0);

  const { type, frames } = data;

  const frameOptions = frames.map((f, index) => ({
    label: getLabelsString(f.labels),
    value: index,
  }));

  return (
    <Box mt={2}>
      {type === 'series' ? (
        <>
          <Select
            transformValue
            fullWidth
            options={frameOptions}
            value={selected}
            onChange={setSelected}
          />
          {frames[selected] && <TimeseriesTable data={frames[selected]} />}
        </>
      ) : (
        <ValueTable frames={frames} />
      )}
    </Box>
  );
};

export default ExpressionResult;
