const variableStore = {
    variables: {},

    setItem(key, value) {
        this.variables[key] = value;
    },

    getItem(key) {
        return this.variables[key] || null;
    }
};

export default variableStore;
