import { DATA_SET } from 'constants/data-sources';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import {
  CONFIG as displayConfig,
  ORIENTATION_OPTIONS,
  STACKING_OPTIONS,
} from '../../PanelSettings/bar-chart/Display';
import { CONFIG as legendConfig } from '../../PanelSettings/Legend';
import { CONFIG as axesConfig } from '../../PanelSettings/Axes';
import { QUERY_TYPE } from 'modules/alarms/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import partition from 'lodash/partition';
import moment from 'moment';
import { noDataMessage } from '../utils';
import { defaultColors, formatTimeLabel } from 'components/UPlot/utils';
import BarChart from 'components/UPlot/BarChart';
import { transformFramesToTable } from '../timeseries';
import randomColor from 'randomcolor';
import { parseValue } from 'components/DateRangePickerNew';

const ID = 'bar_chart';
export const CONFIG = {
  id: ID,
  settings: {
    general: [
      panelConfig,
      { ...displayConfig, props: { name: ID } },
      { ...axesConfig, props: { name: ID, hideYRight: true } },
      { ...legendConfig, props: { name: ID } },
      { ...timeConfig, props: { name: ID } },
    ],
    overrides: null,
  },
  name: 'Bar Chart',
  description: 'Bar Chart',
  multiNodes: true,
  defaultNodeConfig: {
    query_type: QUERY_TYPE.AGG,
    dataset: DATA_SET.METRICS,
    limit: null,
    page: null,
    responseType: 'series',
  },
  defaultConfig: {
    ...axesConfig.default,
    ...displayConfig.default,
    ...legendConfig.default,
  },
  showTableView: true,
  configurableSource: true,
};

const BarChartPanel = ({
  data,
  config,
  timeRange,
  height,
  width,
  tz = 'local',
}) => {
  const formik = useFormikContext();
  const values = config || formik?.values || {};
  const { axis, display, legend } = values[ID];

  const timeValuesTpl = useMemo(() => {
    const { from, to } =
      typeof timeRange === 'string'
        ? parseValue(timeRange, config.time_options?.time_shift)
        : {
            from: moment().subtract(timeRange.from, 'seconds').valueOf(),
            to: moment().subtract(timeRange.to, 'seconds').valueOf(),
          };
    return formatTimeLabel({
      from,
      to,
      dateStyle: axis.x.date_style,
    });
  }, [timeRange, axis.x.date_style]);

  const chart = useMemo(() => {
    const isVertical =
      display.orientation === ORIENTATION_OPTIONS.vertical.value;
    const isGrouped = display.stacking === STACKING_OPTIONS.grouped.value;

    const [seriesFrames, numberFrames] = partition(
      data,
      (o) => o.type === 'series',
    );

    let result, isTimeSeries;
    if (!seriesFrames.length && numberFrames.length) {
      result = transformNumberFramesToTable(numberFrames);
    } else {
      result = transformFramesToTable(seriesFrames);
      isTimeSeries = true;
    }

    const { graphData, labels } = result;
    if (!labels || !labels.length || !data) {
      return (
        <Box height={height} width={width}>
          {noDataMessage}
        </Box>
      );
    }

    const series = [{}];
    labels.forEach((label, index) => {
      let color = defaultColors[index];

      if (!color) {
        color = randomColor({ luminosity: 'bright', seed: label + index });
      }

      series.push({
        points: { show: false },
        stroke: color,
        fill: color,
        name: label,
        width: 0,
      });
    });

    if (!data || !graphData.length || !graphData[0].length) {
      return (
        <Box height={height} width={width}>
          {noDataMessage}
        </Box>
      );
    }

    return (
      <BarChart
        data={graphData}
        height={height}
        width={width}
        series={series}
        horizontal={!isVertical}
        stacked={!isGrouped}
        timeValuesTpl={timeValuesTpl}
        legend={legend}
        tz={tz}
        isTimeSeries={isTimeSeries}
        yFormat={axis.y}
      />
    );
  }, [
    data,
    height,
    width,
    display.orientation,
    display.stacking,
    axis.x.date_style,
    legend.view,
    legend.values,
    timeValuesTpl,
    axis.y,
    tz,
  ]);

  return chart;
};

export default BarChartPanel;

const transformNumberFramesToTable = (frames) => {
  if (!frames)
    return {
      graphData: [],
      labels: [],
    };

  const dataMap = {};

  frames.forEach((frame) => {
    const { values, labels } = frame;
    const label = Object.values(labels).join(',');
    dataMap[label] = values[0];
  });

  return {
    graphData: [Object.keys(dataMap), Object.values(dataMap)],
    labels: [frames[0].name],
  };
};
