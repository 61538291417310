const commonProperties = {
  axis: {
    x: { date_style: 'auto' },
    y: { format: 'number', decimals: '', multiplyBy: '' },
    y1: { format: 'number', decimals: 1 },
  },
  node_configs: {
    dimensions: [],
    source: 'kloudmate',
    filters: [],
    groupBy: [],
    hidden: false,
    orderBy: [],
    time_range: { to: 0, from: 600 },
    type: 'query',
  },

  panelType: 'TimeSeries',
  statConfig: {
    baseColor: '#4CBB17',
    calculation: {
      label: 'Mean',
      value: 'mean',
    },
    format: 'number',
    statView: 'grid',
  },
  timeRange: '1d',
  timeseries: {
    legend: {
      values: [],
    },
    view: 'table',
  },
  tz: 'local',
  widget: 'custom',
};

export const widgets_config = {
  lambda_invocations: {
    ...commonProperties,
    description: 'Total invocations of the Lambda Functions.',
    node_configs: [
      {
        ...commonProperties.node_configs,
        aggregation: {},
        datasourceId: '$Datasource',
        dimensions: {
          FunctionName: ['$FunctionName'],
        },
        filters: [],
        namespace: 'AWS/Lambda',
        region: '$Region',
        stat: 'Sum',
        metric: 'Invocations',
        legendFormat: '{{FunctionName}}',
        nodeId: 'A',
      },
    ],
    title: 'Total Lambda Invocations',
  },
  lambda_errors: {
    ...commonProperties,
    description: 'Total Error of the Lambda Functions',
    node_configs: [
      {
        ...commonProperties.node_configs,
        aggregation: {},
        datasourceId: '$Datasource',
        dimensions: {
          FunctionName: ['$FunctionName'],
        },
        namespace: 'AWS/Lambda',
        region: '$Region',
        stat: 'Sum',
        metric: 'Errors',
        filters: [],
        legendFormat: '{{FunctionName}}',
        nodeId: 'A',
      },
    ],
    title: 'Total Lambda Errors',
  },
  top_errored_services: {
    ...commonProperties,
    description:
      'Total Errors in Services. Errors are aggregated from span data where status.code = 2',
    node_configs: [
      {
        ...commonProperties.node_configs,
        datasourceId: 'kloudmate',
        aggregation: {
          stat: 'COUNT',
          field: '',
          type: '',
        },
        filters: [
          {
            field: 'status.code',
            operator: '=',
            type: 'number',
            value: '2',
          },
        ],
        groupBy: [
          {
            field: 'serviceName',
            type: 'root',
          },
        ],
        dataset: 'traces',
        interval: '5m',
        legendFormat: '{{serviceName}}',
        nodeId: 'A',
        type: 'query',
      },
    ],
    title: 'Top Errored Services',
  },
  log_count: {
    ...commonProperties,
    description: 'Number of log lines ingested over time',
    node_configs: [
      {
        ...commonProperties.node_configs,
        datasourceId: 'kloudmate',
        aggregation: {
          field: '',
          stat: 'COUNT',
          type: 'root',
        },
        dataset: 'logs',
        legendFormat: 'Log Count',
        nodeId: 'A',
        type: 'query',
      },
    ],
    title: 'Log Count',
  },
  span_count: {
    ...commonProperties,
    description: 'Number of spans ingested over time',
    node_configs: [
      {
        ...commonProperties.node_configs,
        datasourceId: 'kloudmate',
        aggregation: {
          field: '',
          stat: 'COUNT',
          type: 'root',
        },
        dataset: 'traces',
        legendFormat: 'Span Count',
        nodeId: 'A',
        type: 'query',
      },
    ],
    title: 'Span Count',
  },
  metrics_points_count: {
    ...commonProperties,
    description: 'Number of metric points ingested over time',
    node_configs: [
      {
        ...commonProperties.node_configs,
        datasourceId: 'kloudmate',
        aggregation: {
          field: '',
          metric: 'KloudMateIngestedMetricPoints',
          metricType: 'Sum',
          stat: 'SUM',
        },
        dataset: 'metrics',
        legendFormat: 'Metrics Points Count',
        nodeId: 'A',
        type: 'query',
      },
    ],
    title: 'Metrics Points',
  },
  services_summary: {
    widget: 'services_summary',
    title: 'Services Overview ',
    description:
      'Summary of services in the workspace and their performance metrics',
  },
  recent_issues: {
    widget: 'recent_issues',
    title: 'Recent Issues',
    description: 'Recent issues in the workspace',
  },
  active_alarms: {
    widget: 'active_alarms',
    description: 'Alarms that are in FIRING state in the workspace',
    title: 'Active Alarms',
  },
  aws_cost_graph: {
    widget: 'aws_cost_graph',
    title: 'AWS Cost & Forecast',
    description: 'Show monthly cost and forecast',
  },
  aws_cost_breakdown: {
    widget: 'aws_cost_breakdown',
    title: 'AWS cost breakdown',
    description: 'Show cost breakdown by service',
  },
};

export const DEFAULT_LAYOUT_CONFIG = {
  span_count: { w: 4, h: 1.5, x: 0, y: 0, minW: 3, minH: 1 },
  metrics_points_count: { w: 4, h: 1.5, x: 12, y: 0, minW: 3, minH: 1 },
  log_count: { w: 4, h: 1.5, x: 4, y: 0, minW: 3, minH: 1 },
  services_summary: { w: 12, h: 2.2, x: 0, y: 1, minW: 6, minH: 2 },
  recent_issues: { w: 6, h: 2, x: 0, y: 2, minW: 2, minH: 2 },
  active_alarms: { w: 6, h: 2, x: 6, y: 2, minW: 2, minH: 2 },
  aws_cost_graph: { w: 7, h: 3, x: 0, y: 8.7, minW: 5, minH: 2 },
  aws_cost_breakdown: { w: 5, h: 3, x: 7, y: 8.7, minW: 5, minH: 2 },
  lambda_invocations: { w: 4, h: 2, x: 0, y: 0, minW: 3, minH: 2 },
  lambda_errors: { w: 4, h: 2, x: 0, y: 0, minW: 3, minH: 2 },
  top_errored_services: { w: 12, h: 3, x: 0, y: 5.7, minW: 3, minH: 2 },
  custom: { w: 4, h: 2, x: 0, y: 0, minW: 2, minH: 1 },
};

export const getInterval = (hours) => {
  switch (hours) {
    case 24:
      return '2400s';
    case 12:
      return '1200s';
    case 6:
      return '600s';
    case 1:
      return '180s';
  }
};

export const variables = [
  {
    config: {
      queryType: 'datasource',
      source: 'cloudwatch',
    },
    description: '',
    id: '1',
    name: 'Datasource',
    type: 'query',
  },
  {
    config: {
      datasourceId: '$Datasource',
      queryType: 'regions',
      source: 'kloudmate',
    },
    description: 'Region of Service',
    id: '2',
    name: 'Region',
    type: 'query',
    value: '$default',
  },
  {
    config: {
      datasource: '$Datasource',
      datasourceId: '$Datasource',
      dimension_keys: 'FunctionName',
      dimensions: [],
      metrics: 'Invocations',
      namespaces: 'AWS/Lambda',
      queryType: 'dimension_values',
      regions: '$Region',
      source: 'kloudmate',
    },
    description: 'Name of the Lambda function',
    id: '3',
    name: 'FunctionName',
    type: 'query',
    value: '',
  },
];
