import React, { useRef, useMemo } from 'react';
import Button from 'components/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import { analyticsTrack } from 'utils/segment';
import Explore from 'modules/explore/Explore';

const EditPanelModal = ({
  panel,
  closeModal,
  onSavePanel,
  resolvedVariablesValues,
  variablesBar,
  defaultRegion,
}) => {
  const formRef = useRef(null);

  const onSaveAndClose = async (values) => {
    onSavePanel('panel', {
      payload: values,
      postSave: () => closeModal(),
    });

    analyticsTrack(!panel.id ? 'Panel Created' : 'Panel Edited', {});
  };

  const onSave = async (values) => {
    onSavePanel('panel', {
      payload: values,
    });

    analyticsTrack(!panel.id ? 'Panel Created' : 'Panel Edited', {});
  };

  return (
    <Dialog open maxWidth="lg" fullWidth scroll="body">
      <DialogTitle>
        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent="space-between"
        >
          Edit Panel
          <DialogActions sx={{ px: 2 }}>
            <Button variant="text" color="inherit" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={async () => {
                await onSaveAndClose(formRef.current.values);
              }}
            >
              Save & Close
            </Button>
            <Button
              onClick={async () => {
                await onSave(formRef.current.values);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Stack>
      </DialogTitle>
      <Box mx={2}>{variablesBar}</Box>
      <Explore
        formRef={formRef}
        resolvedVariablesValues={resolvedVariablesValues}
        panel={panel}
        defaultRegion={defaultRegion}
      />
    </Dialog>
  );
};

export default EditPanelModal;
